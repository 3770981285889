<template>
  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Designation Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter Designation Name here..." maxlength="100" v-if="designation" v-model="designation.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Print Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="designation" v-model="designation.print_name">
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'DesignationForm',
  data () {
    return {
      designation: JSON.parse('{"id":"","code":0,"name":"","print_name":""}')
    }
  },
  props: {
    mydesignation: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":"","code":0,"name":"","print_name":""}')
    }
  },
  beforeMount () {
    this.designation = this.mydesignation; // save props data to itself's data and deal with tms
  },
  component: {

  },
  created () {
    this.$data.designation = JSON.parse('{"id":"","code":0,"name":"","print_name":""}')
  },
  mounted () {

  },
  methods: {
    closeWindow(){
      this.$parent.$parent.closeModal();
    },
    saveDocument(){
      let self = this;

      if(self.$data.designation.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      const requestOptions = {
        method:  (self.$data.designation.code == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.designation)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/designation/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
          self.designation = JSON.parse('{"id":"","code":0,"name":"","print_name":""}');
          self.closeWindow();
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
