<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>Designation's List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="designation-table"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="code" data-width="100">Code</th>
          <th data-field="name" >Name</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i>Discard</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-blocked text-danger" ></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="designation-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="550" :height="270" @before-open="beforeOpen" @before-close="beforeClose">
      <DesignationForm v-bind:mydesignation="designation" ></DesignationForm>
    </vmodal>

  </div>
</template>

<script>
import DesignationForm from '@/components/v1/designation/DesignationForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'

export default {
  name: 'DesignationView',
  components: {
    DesignationForm,
    FabButton
  },
  data () {
    return {
      mytable: {},
      designation: JSON.parse('{"id":"","code":0,"name":"","print_name":""}')
    }
  },
  created () {

  },
  mounted () {
    let self = this;

    this.$data.mytable = $('#designation-table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#designation-table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        var id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        } else if ($(e.target).text() === 'Remove') {
           self.removeDocument(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {
      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
      $('#designation-table>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide()
    });

    self.loadData();
  },
  methods: {
    when_opened(){

    },
    closeModal () {
      this.$modal.hide('designation-window');
    },
    beforeOpen(){
      console.log('beforeOpen');
    },
    beforeClose(){
      this.loadData();
      this.designation = JSON.parse('{"id":"","code":0,"name":"","print_name":""}');
    },
    showModal () {
      this.designation = JSON.parse('{"id":"","code":0,"name":"","print_name":""}');
      this.$modal.show('designation-window')
    },
    modifyDocument (id) {
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.designation = {};
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/designation/${id}/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.designation = data.data;
          self.$modal.show('designation-window');
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, icon:  "error"} );
      });
    },
    removeDocument (id) {
      let self = this;

      self.$data.designation.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.group)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/designation/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if(resp.accepted){
              self.$modal.hide('designation-window');
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            } else {
              swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
            }

            // swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },
    loadData () {
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/designation/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          self.$data.mytable.bootstrapTable('load', data.data);
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });

    },
    save () {
      let self = this;

      if(self.$data.designation.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      const requestOptions = {
        method:  (self.$data.designation.code == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.designation)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/designation/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.accepted) {
          swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
          self.designation = JSON.parse('{"id":"","code":0,"name":"","print_name":""}');
          self.$modal.hide('designation-window');
        } else {
          swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });
    }
  }
}
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
